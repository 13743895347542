import React, { useState, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import { Menu, Layout, Card, Select, message, Row, Col, Drawer, Button } from "antd";

import { LeftOutlined,RightOutlined } from '@ant-design/icons';

import { useGlobalContext } from "../../lib/storage";

import "antd/dist/antd.css";
import "../../styles/app.css";
import "../../styles/antd.css";
import "./menu.css";

const { Sider } = Layout;


const toggleFullHideButtonInactive = {
  position: "absolute",
  bottom: "-4px",
  backgroundColor: "#FFFFFF",
  color: "white",
  height: "30px",
  width: "35px",
  borderRadius: "0px 50px 50px 0px",
  textAlign: "center",
  left: "59px",
  zIndex: "9",
  fontWeight: "bold",
  cursor: "pointer",
  padding: "5px",
};



const SideMenu = (props) => {

  const { globalStore, setGlobalStore } = useGlobalContext();
  const Themes = globalStore.userData.CW360_V2_UI;
  const { sideMenuData: menuList, windowTabs, userPreferences } = globalStore;
  const { menuToggle, setMenuToggle, fullMenuToggle,showToggler, setFullMenuToggle } = props;
  const [menuData, setMenuData] = useState([]);
  const history = useHistory();
  let lowerSubMenu = JSON.parse(localStorage.getItem("lowerSubMenu"));
  const { drawerFlag, onClose,setShowToggler } = props;
  const [menuIdFromUrl,setMenuIdFromUrl] = useState(null)
  const windowType = localStorage.getItem("windowType")
  useEffect(() => {
    const menuIdData = history.location.pathname.split('/')
    if(menuIdData.length > 0){
      setMenuIdFromUrl(menuIdData[3])
    }
    if (menuList) {
      try {
        menuList.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
        menuList.map((item, index) => {
          if (item.children && item.children.length > 0) {
            item.children.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
            item.children.map((subItem, subIndex) => {
              if (subItem.children && subItem.children.length > 0) {
                subItem.children.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
              }
              // item.children[subIndex] = subItem;
              return null;
            });
          }
          menuList[index] = item;
          return null;
        });
        setMenuData(menuList);
      } catch (error) {
        console.error("Failed to set menu data: ", JSON.stringify(error, null, 2));
      }
    }

  }, [menuList]);


  
  const selectMenuToNavigate = (data) => {
    localStorage.setItem("lowerSubMenuItem", JSON.stringify(data.key));
    if (data.type === "Report") {
      localStorage.setItem("windowType","Report")
      history.push(`/reports/report/${data.id}`);
    } else if (data.type === "Dashboard") {
      localStorage.setItem("windowType","Dashboard")
      history.push(`/analytics/dashboard/${data.id}`);
    } else if (data.type === "Generic") {
      history.push(`/window/list/${data.id}`);
    } else if (data.type === "Custom") {
      history.push(`/others/window/${data.id}`);
    } else {
      message.warning("Not Available");
    }
  };

  let lowerSubMenuItem =JSON.parse(localStorage.getItem("lowerSubMenuItem"))

 

  const NavigateToMenu = (menuType, menuId, menuTitle) => {
    setMenuIdFromUrl(menuId)
    let navigationUrl;
    switch (menuType) {
      case "Report":
        navigationUrl = `/reports/report/${menuId}`;
        break;
      case "Dashboard":
        navigationUrl = `/analytics/dashboard/${menuId}`;
        break;
      case "Generic":
        navigationUrl = `/window/list/${menuId}`;
        break;
      case "Custom":
        navigationUrl = `/others/window/${menuId}`;
        break;
      case "GenericNew":
        navigationUrl = `/window/${menuId}/NEW_RECORD`;
        break;
      default:
        message.warning("Not Available");
        break;
    }
    if (navigationUrl) {
      if (userPreferences.enableMultiTab === "Y") {
        const prevWindowTabs = [...windowTabs];
        if (prevWindowTabs.findIndex((tab) => tab.url === navigationUrl) < 0) {
          const newWindowTab = {
            url: navigationUrl,
            title: menuTitle,
            content: null,
          };
          setGlobalStore({ windowTabs: [...prevWindowTabs, newWindowTab] });
          history.push(navigationUrl);
        } else {
          message.warning("Tab Already Active");
        }
      } else {
        history.push(navigationUrl);
      }
    }
    props.onClose();
  };


  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  const fullToggle = () => {
    console.log('fullToggle called with menuToggle =', menuToggle);
    setFullMenuToggle((t) => !t);
    setTimeout(() => {
      setMenuToggle((t) => !t);
      setShowToggler(false);
      console.log('menuToggle set to', menuToggle);
    }, 0);
  }

  let sideMenuStyle = ""
  let rightMargin = ""
  if(menuToggle){
    sideMenuStyle = "0px"
    rightMargin="23px"
  }else{
    sideMenuStyle = "15px"
    rightMargin="0px"
  }

  return (
    <div className="responsiveSideMenu" style={{marginTop:'15px',marginLeft:sideMenuStyle,marginRight:rightMargin,fontFamily:'Inter'}}>
     {lowerSubMenu !== undefined && lowerSubMenu !== null && lowerSubMenu.length !== 0?
       <Sider  collapsed={menuToggle} onCollapse={null} style={{backgroundColor:'#FFFFFF'}} className={menuToggle ? (fullMenuToggle ? "fullmenu-inactive" : "fullmenu-active") : null}>
        <div className="menu-toggle">
          <Button style={{height:"4em",border:"none",paddingRight:"1px"}}  onClick={fullToggle} >
         {menuToggle?<RightOutlined/>:<LeftOutlined/>} 
          </Button>
        </div>
        {/* <Card style={Themes.sideMenu.sideMenuCard}> */}
            <Scrollbars
              style={{
                // marginLeft:'1.5px',
                height:"77vh",
                transition: 'height 0.3s'
              }}
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={200}
              thumbSize={90}
              renderView={renderView}
              renderThumbHorizontal={renderThumb}
              renderThumbVertical={renderThumb}
            >

              <Menu selectedKeys={lowerSubMenuItem} className="sideMenu" style={{backgroundColor:'#FFFFFF',paddingTop:'14px',color:"#0C173A"}} mode="vertical" >
              {lowerSubMenu ? (lowerSubMenu.map((menuList) => {
                  
                  return (
                    <Menu.Item style={{color:"#0C173A",borderBottom: '1px solid rgba(229, 229, 229, 0.4)'}} key={menuList.key} onClick={()=>selectMenuToNavigate({type:menuList.type,id:menuList.id,key:menuList.key})}>{menuList.title}</Menu.Item>
                  )

                }))
              :(
                setMenuToggle(true) && setFullMenuToggle(true)
              )
              }
              </Menu>
            </Scrollbars>
            {/* </Card> */}
       </Sider>:""}
       
    </div>
  );
};

export default SideMenu;
