import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { Row, Col, Popover, Image, Button, Divider, Form, Select, Card, message, Menu, Tooltip, Input, Dropdown } from "antd";
import { useGlobalContext } from "../../lib/storage";
// import Search from "../../assets/images/searchIcon.svg"
import {
  getAdminMenuList,
  get360MenuList,
  getFavouritesMenuList,
  removeFavouriteMenu,
  createFavouriteMenu,
  getLoggedInUserRoles,
  getLoggedInBusinessUnits,
  updateRoles,
  getUser,
  getUserPreferencesData,
} from "../../services/generic";
import {MailOutlined,StarOutlined,SettingOutlined,CloseOutlined
} from '@ant-design/icons'
import MobileMenu from "../mobileMenu";
import NewLogo from "../../assets/images/NewLogoCW.svg";
import ToggleIcon from "../../assets/images/toggleIcon.svg";
import ProfileIcon from "../../assets/images/profileIConNEw.svg"
import MobileLogo from "../../assets/images/mobileLogo.svg";
import SearchIcon from "../../assets/images/searchNewIcon.svg"
import Profile from "../../assets/images/blankImage.png";
import Yellowpin from "../../assets/images/Yellowpin.svg";
import MoreNavs from "../../assets/images/NewMoreNavsIcon.svg";
import UserIcon from "../../assets/images/NewUserIcon.svg";
import DownArrow from "../../assets/images/downArrow.svg";
import mobileGlobalSearch from "../../assets/images/mobileGlobalSearch.svg";
import Appicon from "../../assets/images/appIcon.svg";
import ThemeJson from "../../constants/UIServer.json";
import UserIconMobile from "../../assets/images/profileMobile.svg";
import LogoutIcon from "../../assets/images/logoutIcon.svg";
// import HideMenuIconArrow from "../../assets/images/hideMenuIconArrow.svg";
import MenuToggler from "../../assets/images/menuToggler.svg";
// import CollapseToggleIcon from "../../assets/images/collapseToggleIcon.svg"
import NewLogo1 from "../../assets/images/mobLogo1.svg";
import mailIcon from "../../assets/images/mailIcon.svg"
import starIcon from "../../assets/images/starIcon.svg"
import settingIcon from "../../assets/images/settingIcon1.svg"

import "antd/dist/antd.css";
import "../../styles/app.css";
import "../../styles/antd.css";
import "./style.css"

const { Option, OptGroup } = Select;
const { SubMenu } = Menu;


const NavBar = (props) => {
  const { globalStore, setGlobalStore } = useGlobalContext();
  const history = useHistory();
  const Themes = globalStore.userData.CW360_V2_UI;
  let usersData = JSON.parse(localStorage.getItem("userData"));
  const {showToggler,setShowToggler, setDrawerFlag, drawerFlag, setMenuToggle,
    //  menuToggle,
     setFullMenuToggle, fullMenuToggle } = props;
  const menuList = globalStore.sideMenuData;
  const [menuData, setMenuData] = useState([]);
  const [favouriteMenuData, setFavouriteMenuData] = useState([]);
  const [selectedMainMenuItem,setSelectedMainMenuItem]=useState([])
  const [dashboardMenuData, setDashboardMenu] = useState([]);
  const [visible, setVisible] = useState(false);
  const [mobMenu,setMobMenu] = useState(false);
  const [showSearch,setShowSearch] = useState(false);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [favouritesVisible, setFavouritesVisible] = useState(false);
  const [reportsVisible, setReportsVisible] = useState(false);
  const [dashBoardSearchInput, setDashBoardSearchInput] = useState("");
  const [initialDashboardMenuData, setInitialDashboardMenu] = useState([]);
  const [userRoleResposeData, setUserRoleResposeData] = useState([]);
  const [userBusinessUnitData, setUserBusinessUnitData] = useState([]);
  const [bunitId, setBunitId] = useState(null);
  const [roleId, setRoleId] = useState(null);
  const [form] = Form.useForm();
  const data = {
    name: globalStore.userData.user,
    Email: globalStore.userData.email,
    Phone: 9876543210,
  };

  useEffect(() => {
    // getCwLogos();
    getFaviouritesMenus();
    if (menuList) {
      try {
        menuList.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
        menuList.map((item, index) => {
          if (item.children && item.children.length > 0) {
            item.children.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
          }
          menuList[index] = item;
          return null;
        });
        setMenuData(menuList);
        // 
      } catch (error) {
        console.error("Failed to set menu data: ", JSON.stringify(error, null, 2));
      }
    }
    setBunitId(globalStore.userData.bunit_id);
    setRoleId(globalStore.userData.role_id);
  }, []);

  const logout = () => {
    setGlobalStore({ authTokens: null, userData: null, sideMenuData: null, userPreferences: null, windowTabs: [] });
    localStorage.clear();
    history.push("/login");
  };

 
  

  const goToHome = async () => {
    let menuDataResponse = JSON.parse(localStorage.getItem("sideMenuData"));
    if (menuDataResponse === null) {
      menuDataResponse = await get360MenuList(usersData.role_id);
      localStorage.setItem("sideMenuData", JSON.stringify(menuDataResponse));
    }
    setGlobalStore({ sideMenuData: menuDataResponse });
    history.replace("/");
  };

  // const getCwLogos = async () =>{
  //   const logosResponse = await getAdminMenuListForDynamicLogos(usersData.role_id);
  //   // const cwLogo = logosResponse.cwLogo
  //   // const clientLogo = logosResponse.clientLogo
  //   // setCWLogo(cwLogo)
  //   // setClientLogo(clientLogo)
  // }

  const getSubMenu=(subMenuItem)=>{
    localStorage.setItem("subMenuItem", JSON.stringify(subMenuItem.key))
  }

  const navigateToFirstChild = (data) => {
    if (data.children === undefined || data.children === null) {
      localStorage.removeItem('lowerSubMenu')
      localStorage.setItem("subMenuItem", JSON.stringify(data.key))
      setMenuToggle(true)
      setFullMenuToggle(true)
      if (data.type === "Report") {
        history.push(`/reports/report/${data.id}`);
      } else if (data.type === "Dashboard") {
        localStorage.setItem("windowType","Dashboard")
        history.push(`/analytics/dashboard/${data.id}`);
      } else if (data.type === "Generic") {
        history.push(`/window/list/${data.id}`);
      } else if (data.type === "Custom") {
        history.push(`/others/window/${data.id}`);
      }
    }
    else {
      localStorage.setItem("subMenuItem", JSON.stringify(data.key))
      localStorage.setItem("lowerSubMenu", JSON.stringify(data.children))
      localStorage.setItem("lowerSubMenuItem", JSON.stringify(data.children[0].key))
      if (data.children[0].type === "Report") {
        localStorage.setItem("windowType","Report")
        history.push(`/reports/report/${data.children[0].id}`);
      } else if (data.children[0].type === "Dashboard") {
        localStorage.setItem("windowType","Dashboard")
        history.push(`/analytics/dashboard/${data.children[0].id}`);
      } else if (data.children[0].type === "Generic") {
        history.push(`/window/list/${data.children[0].id}`);
      } else if (data.children[0].type === "Custom") {
        history.push(`/others/window/${data.children[0].id}`);
      }

    }

  }

 
  const getAdminMenus = async () => {
    let menuDataResponse = JSON.parse(localStorage.getItem("adminMenuData"));
    if (menuDataResponse === null) {
      menuDataResponse = await getAdminMenuList(usersData.role_id);
      localStorage.setItem("adminMenuData", JSON.stringify(menuDataResponse));
    }
    history.replace("/");
    setGlobalStore({ sideMenuData: menuDataResponse });
  };

  const getFaviouritesMenus = async () => {
    const favouritesMenuDataResponse = await getFavouritesMenuList();
    setFavouriteMenuData(favouritesMenuDataResponse);
  };

  const deleteFavourites = async (id) => {
    const removeFavouriteMenuResponse = await removeFavouriteMenu(id);
    message.success(removeFavouriteMenuResponse);
    getFaviouritesMenus();
  };

  const selectMenu = async (value, data) => {
    const valueData = value.split("@");
    const type = valueData[1];
    const addFavouritesResponse = await createFavouriteMenu(data.key, data.children, data.url, type, globalStore.userData.cs_client_id);
    message.success(addFavouritesResponse);
    getFaviouritesMenus();
  };

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  const getFilteredMenuData = (menuParam, filterKey) => {
    return menuParam.filter((menu) => {
      if (menu.children) {
        const subChildren = menu.children.filter((c) => c.type === filterKey);
        return subChildren.length > 0;
      } else {
        return false;
      }
    });
  };

  const getFilteredSubMenuData = (menuParam, filterKey) => {
    return menuParam.filter((c) => c.type === filterKey);
  };

  const getMainMenu = (menuItem) => {
    menuData.map((menu, index) => {
      if (menu.key === menuItem.key) {
        localStorage.setItem("selectecMianMenuItem", JSON.stringify(menuItem.key))
        localStorage.setItem("subMenuData", JSON.stringify(menu.children))
        setSelectedMainMenuItem(menu.children)
      }
    })
  }

  const getDashboard = () => {
    let dashboardMenu = [];
    let undefinedParent = []
    const menuData1 = []
    for (let index = 0; index < menuData.length; index++) {
      const element = menuData[index].children;
      if(element === undefined || element === null){
        if(menuData[index].type === "Dashboard"){
          undefinedParent.push(menuData[index])
        }
      }else{
        menuData1.push(menuData[index])
      }
    }
    menuData1.map((menu) => {
      return menu.children.map((data) => {
        if (data.type === "Dashboard") {
          dashboardMenu.push(data);
          return null;
        } else {
          return null;
        }
      });
    });
    const allArray = dashboardMenu.concat(undefinedParent);
    setDashboardMenu(allArray);
    setInitialDashboardMenu(allArray);
  };

  useEffect(() => {
    if (dashBoardSearchInput !== "") {
      const dashBoardSearchResuts = initialDashboardMenuData.filter((m) => m.title.toLowerCase().search(dashBoardSearchInput.toLowerCase()) >= 0);
      setDashboardMenu([...dashBoardSearchResuts]);
    } else {
      getDashboard();
    }
  }, [dashBoardSearchInput, menuData]);

  const handleVisibleChange = (visible) => {
    setVisible(visible);
  };

  const handleFavouritesChange = (visible) => {
    setFavouritesVisible(visible);
  };
  const handleReportsChange = (visible) => {
    setReportsVisible(visible);
  };

  const dashBoardNavigate = (key) => {
    setVisible(false);
    history.push(`/analytics/dashboard/${key}`);
  };

  const onFavourites = (data) => {
    setFavouritesVisible(false);
    if (data.type === "Report") {
      history.push(`/reports/report/${data.menuId}`);
    } else if (data.type === "Dashboard") {
      history.push(`/analytics/dashboard/${data.menuId}`);
    } else if (data.type === "Generic") {
      history.push(`/window/list/${data.menuId}`);
    } else if (data.type === "Custom") {
      history.push(`/others/window/${data.menuId}`);
    } else {
      message.warning("Not Available");
    }
  };
  const onReports = (subMenuItem) => {
    setReportsVisible(false);
    history.push(`/reports/report/${subMenuItem.id}`);
  };

  const getLoggedInUserRolesData = async () => {
    const userRolesRespose = await getLoggedInUserRoles(usersData.user_id);
    setUserRoleResposeData(userRolesRespose);
  };

  const getLoggedInUserBusinessUnitData = async () => {
    const userBusinessUnitResponse = await getLoggedInBusinessUnits(usersData.user_id);
    setUserBusinessUnitData(userBusinessUnitResponse);
  };

  const onSelectRole = (e) => {
    setRoleId(e);
  };

  const onSelectBusinessUnit = (e) => {
    setBunitId(e);
  };

  const changeRoleAndBusinessUnit = async () => {
    const rolesAndBusinessResponse = await updateRoles(roleId, bunitId);
    if (rolesAndBusinessResponse.messageCode === "200") {
      processForUpdate();
    } else {
      message.error(rolesAndBusinessResponse.message);
    }
  };

  const processForUpdate = async () => {
    const userDataResponse = await getUser(globalStore.userData.username);
    userDataResponse.username = globalStore.userData.username;
    if (!userDataResponse) {
      throw new Error("Invalid User Data Response");
    }
    if (userDataResponse.CW360_V2_UI === null || userDataResponse.CW360_V2_UI === undefined) {
      userDataResponse.CW360_V2_UI = ThemeJson;
    } else {
      userDataResponse.CW360_V2_UI = JSON.parse(userDataResponse.CW360_V2_UI);
    }

    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      userDataResponse.CW360_V2_UI = ThemeJson;
    }

    localStorage.setItem("userData", JSON.stringify(userDataResponse));

    const userPreferencesResponse = await getUserPreferencesData();
    localStorage.setItem("userPreferences", JSON.stringify(userPreferencesResponse));

    const menuDataResponse = await get360MenuList(userDataResponse.role_id);
    localStorage.setItem("sideMenuData", JSON.stringify(menuDataResponse));
    setGlobalStore({ userData: userDataResponse, sideMenuData: menuDataResponse, userPreferences: userPreferencesResponse });
    history.push("/");
    window.localStorage.removeItem("windowDefinitions");
    window.location.reload();
  };

  const routeToProfile = () =>{
    history.push("/others/window/7465");
  }

  const content = (
    <Card style={Themes.navBar.logoutCardStyle}>
      <Row>
        <Col span={6}>
          <Image alt="profile" src={Profile} style={Themes.navBar.profileIcon} />
        </Col>
        <Col span={18} onClick = {routeToProfile} style={{ paddingLeft: "30px",cursor:'pointer' }}>
          <Row>
            <Col span={24} style={Themes.navBar.profileName}>
              {data.name}
            </Col>
            <Tooltip title={data.Email}>
              <Col
                span={24}
                style={{
                  width: "auto",
                  maxWidth: "100%",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  position: "relative",
                  fontSize: "10px",
                  fontWeight: "500",
                }}
              >
                {data.Email === "null" ? "" : data.Email}
              </Col>
            </Tooltip>
            <Col span={24} style={Themes.navBar.profileInfo}>
              {data.Phone === "null" ? "" : data.Phone}
            </Col>
          </Row>
        </Col>
        <Divider style={Themes.navBar.dividerOne} />
        <Col span={24}>
          <Form layout="vertical" name={form}>
            <Row style={{ margin: "-4px" }}>
              <Col span={24}>
                <Form.Item name="roleName" label="Roles" style={Themes.navBar.roles}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    className="certain-category-search"
                    dropdownClassName="certain-category-search-dropdown"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onFocus={getLoggedInUserRolesData}
                    onSelect={onSelectRole}
                    defaultValue={globalStore.userData.role_iden}
                  >
                    {/* <Option value="CW Solutions">CW Solutions</Option> */}
                    {userRoleResposeData.map((data, index) => (
                      <Option key={data.recordid} value={data.recordid}>
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="bunitName" label="Business Unit" style={Themes.navBar.businessUnit}>
                  <Select
                    showSearch
                    style={{ width: "100%" }}
                    className="certain-category-search"
                    dropdownClassName="certain-category-search-dropdown"
                    optionFilterProp="children"
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onFocus={getLoggedInUserBusinessUnitData}
                    onSelect={onSelectBusinessUnit}
                    defaultValue={globalStore.userData.bunit_iden}
                  >
                    {userBusinessUnitData.map((data, index) => (
                      <Option key={data.recordid} value={data.recordid}>
                        {data.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24} style={{ textAlign: "right" }}>
                <Form.Item style={Themes.navBar.businessUnit}>
                  <Button style={Themes.navBar.cancelButton}>Cancel</Button>
                  <Button onClick={changeRoleAndBusinessUnit} type="primary" htmlType="submit" style={Themes.navBar.applyButton}>
                    Apply
                  </Button>
                </Form.Item>
              </Col>
              <Divider style={Themes.navBar.dividerTwo} />
              <Button type="link" onClick={logout} style={Themes.navBar.logoutButton}>
                <Row>
                  <img style={{ marginTop: "-10px" }} src={LogoutIcon} alt="Logo" />
                  &nbsp;&nbsp;
                  <p style={Themes.navBar.logoutButton.logoutText}>Logout</p>
                </Row>
              </Button>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  );

  const favMenuContent = (
    <div style={{ width: "18em" }}>
      <Select
        showSearch
        style={{ width: "100%", paddingRight: "8px" }}
        suffixIcon={<i className="fa fa-search" role="presentation" aria-hidden="true" style={Themes.sideMenu.sideMenuSearchIcon} />}
        onSelect={selectMenu}
        value={null}
        placeholder={
          <Row>
            <Col span={4}>
              <i className="fa fa-search" role="presentation" aria-hidden="true" style={Themes.sideMenu.sideMenuSearchIcon} />
            </Col>
            <Col span={20}>Search...</Col>
          </Row>
        }
        showArrow={false}
        className="search-arrow placeHolder"
      >
        {menuData.map((menu, index) => (
          <OptGroup key={`${menu.title}-${index}`} label={menu.title}>
            {menu.children
              ? menu.children.map((subMenuItem) => (
                  <Option key={subMenuItem.id} url={subMenuItem.url} value={`${subMenuItem.title}@${subMenuItem.type}`}>
                    {subMenuItem.title}
                  </Option>
                ))
              : null}
          </OptGroup>
        ))}
      </Select>
      <p />
      <ul style={{ listStyleType: "none", paddingLeft: "0px" }}>
        {favouriteMenuData !== null
          ? favouriteMenuData.map((data) => {
              return (
                <li key={data.menuId} style={{ paddingTop: "2px", paddingBottom: "2px", cursor: "pointer" }}>
                  <Row gutter={16}>
                    <Col span={20}>
                      <span
                        onClick={() => {
                          onFavourites(data);
                        }}
                      >
                        {data.menuName}
                      </span>
                    </Col>
                    <Col span={4}>
                      <img alt="pin" src={Yellowpin} onClick={() => deleteFavourites(data.id)} />
                    </Col>
                  </Row>
                </li>
              );
            })
          : ""}
      </ul>
    </div>
  );

  const selectReportMenuSearch = (value) => {
    history.push(`/reports/report/${value}`);
  };

  const NavigateToMenu = (menuType, menuId, menuTitle) => {
    let navigationUrl;
    switch (menuType) {
      case "Report":
        navigationUrl = `/reports/report/${menuId}`;
        break;
      case "Dashboard":
        navigationUrl = `/analytics/dashboard/${menuId}`;
        break;
      case "Generic":
        navigationUrl = `/window/list/${menuId}`;
        break;
      case "Custom":
        navigationUrl = `/others/window/${menuId}`;
        break;
      case "GenericNew":
        navigationUrl = `/window/${menuId}/NEW_RECORD`;
        break;
      default:
        message.warning("Not Available");
        break;
    }
    history.push(navigationUrl);
  };

  const onTogMenu = () => {
    if(mobMenu === true){
      setMobMenu(false)
    }setMobMenu(true)
  }

  // const selectMenuToNavigate = (value, data) => {
  //   // console.log("value, data========>",value)
  //   // console.log("data===========>",data)
  //   menuData.map(menu=>{
  //     menu.children.map(res=>{
  //       console.log("res.id==========>",res.id)
  //       if(res.id===data.key1){
  //         console.log("called===========>")
  //         localStorage.setItem("subMenuData", JSON.stringify(menu.children))
  //         localStorage.setItem("subMenuItem", JSON.stringify(res.key))
  //         localStorage.setItem("selectecMianMenuItem", JSON.stringify(menu.key))
  //         localStorage.setItem("lowerSubMenu",res.children?res.children:null)
  //       }else{
  //         console.log("inside else block")
  //       }
  //     })
  //   })

  //   const valueData = value.split("@");
  //   if (valueData.length > 0) {
  //     NavigateToMenu(valueData[1], data.key1, data.children);
  //   }
  // };

  const selectMenuToNavigate = (value, data) => {

    menuData.map(menu=>{
      menu.children.map(res=>{
        if(res.id == data.key1){
          localStorage.setItem("subMenuData", JSON.stringify(menu.children))
          localStorage.setItem("subMenuItem", JSON.stringify(res.key))
          localStorage.setItem("selectecMianMenuItem", JSON.stringify(menu.key))
          localStorage.setItem("lowerSubMenu",null)
        }else{
          res?.children?.map(child=>{
            if(child.id == data.key1){
              localStorage.setItem("subMenuData", JSON.stringify(menu.children))
              localStorage.setItem("subMenuItem", JSON.stringify(res.key))
              localStorage.setItem("selectecMianMenuItem", JSON.stringify(menu.key))
              localStorage.setItem("lowerSubMenu",JSON.stringify(res.children?res.children:null))
              localStorage.setItem("lowerSubMenuItem", JSON.stringify(child.key));
            }
          })
        }
        // if(res.id===data.key1){
        //   localStorage.setItem("subMenuData", JSON.stringify(menu.children))
        //   localStorage.setItem("subMenuItem", JSON.stringify(res.id))
        //   localStorage.setItem("selectecMianMenuItem", JSON.stringify(menu.id))
        //   localStorage.setItem("lowerSubMenu",res.children?res.children:null)
        // }else{
        //   console.log("inside else block")
        // }
      })
    })

    const valueData = value.split("@");
    if (valueData.length > 0) {
      NavigateToMenu(valueData[1], data.key1, data.children);
    }
  };

  const subMenuNavigate = (data) => {
    if (data.children === undefined || data.children === null) {
      localStorage.setItem("subMenuItem", JSON.stringify(data.key))
      localStorage.setItem("lowerSubMenu",null)
      localStorage.setItem("windowType",data.type)
      setMenuToggle(true)
      setFullMenuToggle(true)
      if (data.type === "Report") {
        history.push(`/reports/report/${data.id}`);
      } else if (data.type === "Dashboard") {
        history.push(`/analytics/dashboard/${data.id}`);
      } else if (data.type === "Generic") {
        history.push(`/window/list/${data.id}`);
      } else if (data.type === "Custom") {
        history.push(`/others/window/${data.id}`);
      }
    }
    else {
      setMenuToggle(false)
      localStorage.setItem("lowerSubMenu", JSON.stringify(data.children))
      localStorage.setItem("lowerSubMenuItem", JSON.stringify(data.children[0].key))
      localStorage.setItem("subMenuItem", JSON.stringify(data.key))
      localStorage.setItem("windowType",data.type)
      if (data.children[0].type === "Report") {
        localStorage.setItem("windowType","Report")
        history.push(`/reports/report/${data.children[0].id}`);
      } else if (data.children[0].type === "Dashboard") {
        localStorage.setItem("windowType","Dashboard")
        history.push(`/analytics/dashboard/${data.children[0].id}`);
      } else if (data.children[0].type === "Generic") {
        history.push(`/window/list/${data.children[0].id}`);
      } else if (data.children[0].type === "Custom") {
        history.push(`/others/window/${data.children[0].id}`);
      }
    }
  };



  const handleReportssList = () => {
    history.push("/reports");
  }

  const reportMenuContent = (
    <div style={{ width: "18em", height: "220px" }}>
      <div>
        <Select
          showSearch
          style={{ width: "100%", paddingRight: "8px" }}
          suffixIcon={<i className="fa fa-search" role="presentation" aria-hidden="true" style={Themes.sideMenu.sideMenuSearchIcon} />}
          onSelect={selectReportMenuSearch}
          value={null}
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          listHeight={180}
          allowClear={true}
          placeholder={
            <Row>
              <Col span={4}>
                <i className="fa fa-search" role="presentation" aria-hidden="true" style={Themes.sideMenu.sideMenuSearchIcon} />
              </Col>
            </Row>
          }
          showArrow={false}
          className="search-arrow placeHolder"
        >
          {getFilteredMenuData(menuData, "Report").map((menu, index) =>
            menu.children ? (
              getFilteredSubMenuData(menu.children, "Report").map((subMenuItem) => (
                <Option key={subMenuItem.id} value={subMenuItem.id}>
                  {subMenuItem.title}
                </Option>
              ))
            ) : (
              <Option key={menu.id} value={menu.id}>
                {menu.title}
              </Option>
            )
          )}
        </Select>
      </div>
      <Scrollbars
        style={{
          height: "190px",
        }}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        thumbSize={90}
        renderView={renderView}
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
      >
        <Menu theme="light" mode="inline">
          {getFilteredMenuData(menuData, "Report").map((menu) => (
            <SubMenu
              key={`${menu.key}`}
              icon={
                <span className={menu.icon} style={{ color: "#666666" }}>
                  &ensp;
                </span>
              }
              title={`${menu.title}`}
            >
              {menu.children
                ? getFilteredSubMenuData(menu.children, "Report")
                    .sort((a, b) => (a.title > b.title ? 1 : -1))
                    .map((subMenuItem, index) => (
                      <Menu.Item key={`${subMenuItem.key}-${index}`} onClick={() => onReports(subMenuItem)} title={subMenuItem.title}>
                        {subMenuItem.title}
                      </Menu.Item>
                    ))
                : null}
            </SubMenu>
          ))}
        </Menu>
      </Scrollbars>
    </div>
  );

  const handleDashboardsList = () => {
    history.push("/dashboards");
  }

  const dashboardMenuContent = (
    <div style={{ width: "18em", height: "250px", marginRight: "-10px" }}>
      <Input
        style={{ width: "100%", paddingRight: "8px" }}
        prefix={<i className="fa fa-search" role="presentation" aria-hidden="true" style={Themes.sideMenu.sideMenuSearchIcon} />}
        value={dashBoardSearchInput}
        onChange={(e) => setDashBoardSearchInput(e.target.value)}
        allowClear={true}
      />
      <Scrollbars
        style={{
          height: "220px",
        }}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        thumbSize={90}
        renderView={renderView}
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
      >
        {dashboardMenuData.map((menu) => {
          return (
            <div
              style={{
                height: "90px",
                width: "100px",
                border: "0px solid gray",
                backgroundColor: "#F5F5F5",
                display: "inline-block",
                borderRadius: "5px",
                paddingBottom: "20px",
                fontSize: "11px",
                paddingTop: "20px",
                margin: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
                textAlign: "center",
                verticalAlign: "middle",
                cursor: "pointer",
              }}
              id={menu.id}
              onClick={() => dashBoardNavigate(menu.id)}
            >
              {menu.title}
            </div>
          );
        })}
      </Scrollbars>
    </div>
  );

  const responsiveDesignNew = {
    xxl: 24,
    xl: 24,
    lg: 24,
    xs: 24,
    sm: 24,
    md: 24,
  };

  const responsiveDesignForColumn = {
    xxl: 8,
    xl: 8,
    lg: 8,
    xs: 6,
    sm: 6,
    md: 6,
  };

  const responsiveSearch = {
    xxl: 8,
    xl: 8,
    lg: 8,
    xs: 8,
    sm: 8,
    md: 6,
  };
  const globalSearch = {
    xxl: 8,
    xl: 8,
    lg: 8,
    xs: 0,
    sm: 16,
    md: 12,
  };

  const responsiveSearch1 = {
    xxl: 14,
    xl: 14,
    lg: 14,
    xs: 8,
    sm: 8,
    md: 16,
  };
const responsiveMenuToggle={
  xxl: 0,
  xl: 0,
  lg: 0,
  xs: 8,
  sm: 8,
  md: 0,
}
  const forCollapse = {
    xxl: 0,
    xl: 0,
    lg: 0,
    xs: 24,
    sm: 24,
    md: 0,
  }

  const responsiveToggle = {
    xxl: 24,
    xl: 24,
    lg: 24,
    xs: 0,
    sm: 0,
    md: 24,
  };

  const responsiveIcons = {
    xxl: 0,
    xl: 0,
    lg: 0,
    xs: 0,
    sm: 0,
    md: 0,
  };

  const responsiveUserText = {
    xxl: 8,
    xl: 8,
    lg: 10,
    xs: 0,
    sm: 0,
    md: 24,
  };

  const responsiveUserIcon = {
    xxl: 0,
    xl: 0,
    lg: 0,
    xs: 8,
    sm: 8,
    md: 0,
  };

  const responsiveUserIconDown = {
    xxl: 0,
    xl: 0,
    lg: 0,
    xs: 12,
    sm: 12,
    md: 0,
  };

  const responsiveSpace = {
    xxl: 0,
    xl: 0,
    lg: 0,
    xs: 14,
    sm: 14,
    md: 0,
  };

  const moreMenu = (
    <Col {...responsiveDesignNew} style={{ height: "125px", width: "110px", paddingLeft: "15px" }}>
      <Row>
        <Popover
          title={<p style={{ paddingBottom: "0px", marginBottom: "0px", textAlign: "center" }}>Favourites</p>}
          placement="bottom"
          style={{ marginLeft: "120px" }}
          content={favMenuContent}
          trigger="hover"
          visible={favouritesVisible}
          onVisibleChange={handleFavouritesChange}
        >
          <p style={{ marginBottom: "5px" }}>Favourites</p>
        </Popover>
      </Row>
      <Row>
        <Popover
          title={<p style={{ paddingBottom: "0px", marginBottom: "0px", textAlign: "center" }}>Reports</p>}
          placement="bottom"
          style={{ marginLeft: "120px" }}
          content={reportMenuContent}
          trigger="hover"
          visible={reportsVisible}
          onVisibleChange={handleReportsChange}
        >
          <p style={{ marginBottom: "5px" }}>Reports</p>
        </Popover>
      </Row>
      <Row>
        <Popover
          title={<p style={{ paddingBottom: "0px", marginBottom: "0px", textAlign: "center" }}>Dashboards</p>}
          placement="bottom"
          style={{ marginLeft: "120px" }}
          content={dashboardMenuContent}
          trigger="hover"
          visible={visible}
          onVisibleChange={handleVisibleChange}
          onClick={handleDashboardsList}
        >
          <p style={{ marginBottom: "5px" }}>Dashboards</p>
        </Popover>
      </Row>
      <Row onClick={getAdminMenus}>
        <Tooltip title="Settings">
          <p style={{ marginBottom: "5px" }}>Settings</p>
        </Tooltip>
      </Row>
    </Col>
  );

  const fullToggle = () =>{
    setFullMenuToggle((t) => !t)
    setMenuToggle((t) => !t)
    setShowToggler(false)
  }

  const VisileToggler = () =>{
    setShowToggler((t) => !t)
  }

  const VisileToggler1 =() =>{
    setShowToggler((t) => !t)
    setDrawerFlag((t) => !t)
  }
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}
const innerWidth = windowSize.innerWidth
  let selectecMianMenuItem = JSON.parse(localStorage.getItem("selectecMianMenuItem"))
  let subMenuItem = JSON.parse(localStorage.getItem("subMenuItem"))
  let subMenuData =JSON.parse(localStorage.getItem("subMenuData"))
  return (
    <>
    {innerWidth >768 ?
    <div style={{height:innerWidth>576?'120px':'88px'}}>
      <Row style={{height:'39px',backgroundColor:Themes.appTheme.primaryColor}}>
      <Col span={24}>
       <Menu selectedKeys={selectecMianMenuItem} className="firstMenu" onClick={getMainMenu} style={{backgroundColor:Themes.appTheme.primaryColor,color:'#F5F5F5',lineHeight:'38px',borderBottom: "none", height:"39px",fontFamily:'Inter',fontWeight:400,width:'100%'}} mode="horizontal">
       <Menu.Item key='appIcon'><img style={{ cursor: "pointer",marginRight:"-2em"}} src={Appicon} preview={false} /></Menu.Item>
        {menuData.map(menu=>{
          return(
          <Menu.Item onClick={() => navigateToFirstChild({ type: menu.children[0].type, id: menu.children[0].id, key: menu.children[0].key, children: menu.children[0].children})} key={menu.key}>{menu.title}</Menu.Item>
          )
        })}
    </Menu>
       </Col>
     </Row>
       <Row justify="space-between" style={{height:innerWidth>576?"50px":'40px',backgroundColor:"#FFFFFF",marginBottom:""}}>
        <Col {...responsiveDesignForColumn}>
          <Row>
          <Col {...responsiveSearch1} style={{ marginTop: innerWidth > 1004 ?"0.7em":"",marginLeft:"1em" }}>
            {innerWidth>1004?
                    <div style={{display:"flex"}}>
                    {/* <img src={ToggleIcon} style={{ display: innerWidth > 1004 ? "none" : "block",cursor: "pointer", paddingBottom: "2px" }} onClick={onTogMenu} alt="" /> */}
                    <img
                      onClick={goToHome}
                      style={{ cursor: "pointer",width:'55%',paddingLeft:'8%',paddingBottom:"4px"}}
                      src={NewLogo}
                      alt="Logo"
                    />
                    </div>:
                    <div style={{display:"flex",marginTop:"0.8em"}}>
                    {/* <img src={ToggleIcon} style={{ cursor: "pointer", paddingBottom: "0.5px",width:"1.5em",height:"1.5em" }} onClick={onTogMenu} alt="" /> */}
                    <img
                      onClick={goToHome}
                      style={{ cursor: "pointer",width:'75%',paddingLeft:'8%',marginLeft:innerWidth<576?"135%":""}}
                      src={NewLogo}
                      alt="Logo"
                    />
                    </div>
                    }
            </Col>
          </Row>
        </Col>
        <Col {...globalSearch} style={{marginTop:innerWidth > 1004 ? "-7px":"-7px",display:innerWidth<576 ? "none":"block"}}>
            <Select
            className="global-sel"
            showSearch
            style={{ width: "100%" ,padding:0,borderRadius:'5px',borderColor:'#D7DADE',border:'0.5px'}}
            value={null}
            onSelect={selectMenuToNavigate}
            placeholder={
              <Row>
                <Col style={{ textAlign: "right" }} span={1}>
                <img src={SearchIcon} alt='' style={{opacity:0.83,padding:"2px 2px 4px 2px",height:"19px"}}/>
                </Col>
                <Col span={23} style={{color:'#868B8F',width:'100%',fontFamily:'Inter',paddingLeft:7,textAlign:'left',fontWeight:400}}>Search</Col>
              </Row>
            }
            showArrow={false}
            // className="search-arrow placeHolder"
          >
            {menuData.map((menu) =>
                  menu.children ? (
                    <OptGroup label={<span style={{ fontSize: '13px', color: 'black' }}><strong>{menu.title}</strong></span>}>
                     {  menu.children.map((subMenuItem) =>
                      subMenuItem.children ? (
                        subMenuItem.children.map((lowerSubMenuItem) => (
                          <Option style={{fontSize:'12px',fontFamily:'Inter' }} key={lowerSubMenuItem.key} key1={lowerSubMenuItem.id} value={`${lowerSubMenuItem.title}@${lowerSubMenuItem.type}`}>
                            {lowerSubMenuItem.title}
                          </Option>
                        ))
                      ) : (
                        <Option style={{fontSize:'12px',fontFamily:'Inter'}} key={subMenuItem.key} key1={subMenuItem.id} value={`${subMenuItem.title}@${subMenuItem.type}`}>
                          {subMenuItem.title}
                        </Option>
                      )
                    )
                  }
                    </OptGroup>
                  ):(
                    <Option style={{fontSize:'12px',fontFamily:'Inter' }} key={menu.key} key1={menu.id} value={`${menu.title}@${menu.type}`}>
                      {menu.title}
                    </Option>
                  )
                )}
          </Select>
        </Col>
        <Col {...responsiveSearch} style={{marginTop:"-6px"}}>
          <Row justify='end'>
            {/* <Col {...responsiveSpace} /> */}
            {/* <Col {...responsiveIcons} style={{ textAlign: "right"}}>
            <img src={mailIcon} alt='' style={{cursor:'pointer'}} />
            </Col> */}
            {/* <Col {...responsiveIcons} style={{ textAlign: "right" }}>
            <Popover
                className="favouritesPopup"
                title={<p style={{ paddingBottom: "0px", marginBottom: "0px", textAlign: "center" }}>Favourites</p>}
                placement="bottom"
                style={{top:'50px'}}
                content={favMenuContent}
                visible={favouritesVisible}
                onVisibleChange={handleFavouritesChange}
              >
             <img src={starIcon} alt='' style={{cursor:'pointer'}} />
            </Popover>
            </Col> */}
            {/* <Col {...responsiveIcons} style={{ textAlign: "right"}}>
            <img src={settingIcon} alt='' style={{cursor:'pointer'}} />&nbsp;&nbsp;
            </Col> */}
            {/* <Col   {...responsiveIcons} style = {{textAlign:"center"}}>
            
            </Col> */}
            <Col {...responsiveUserText} style={{ textAlign: "center",marginTop:'0px',flexBasis:"fit-content"}}>
              <Popover content={content} placement="topRight" arrowPointAtCenter>
                <div style={{display:"flex"}}>
              <img src = {ProfileIcon} style={{cursor:"pointer"}}/>&nbsp;&nbsp;
                <span style={{color:'#0C173A',whiteSpace:"nowrap",fontFamily:'Open Sans',fontWeight:'bold',textAlign:'right',cursor:'pointer'}}>
                  {data.name} &nbsp; &nbsp;&nbsp;
                </span>
                </div>
              </Popover>
            </Col>
            <Col {...responsiveUserIconDown} />
            {/* <Col {...responsiveUserIcon} style={{ textAlign: "right", marginTop: "-10px" }}>
              <Dropdown trigger={["click"]} overlay={moreMenu}>
                <img style={{ cursor: "pointer",backgroundColor:'#FFFFFF' }} src={MoreNavs} preview={false} />
              </Dropdown>
            </Col> */}
            <Col {...responsiveUserIcon} style={{ textAlign:"center",marginRight:"0.6em",float:"right" }}>
              <Popover content={content} trigger="click" placement="topRight">
                <img style={{ cursor: "pointer",backgroundColor:'#FFFFFF' }} src={UserIcon} preview={false} />
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>
      {innerWidth>576 ?
      <Row style={{height:'20px'}}>
      <Col {...responsiveToggle} >
      <Menu selectedKeys={subMenuItem} onClick={getSubMenu}  className="secondMenu" style={{backgroundColor: "white",lineHeight:'36px', height:"22px",fontFamily:'Inter',fontWeight:400,alignItems:"center",opacity:'100%',paddingLeft:"2em"}} mode="horizontal">
      {subMenuData?.map((subMenu) => {
             return (
               <Menu.Item key={subMenu.key} onClick={()=>subMenuNavigate({type:subMenu.type,id:subMenu.id,key:subMenu.key,children:subMenu.children})}>{subMenu.title}</Menu.Item>
             )
           })}
      </Menu>
      </Col>
      <Col {...forCollapse} style={{marginTop:'-13.5px',backgroundColor:'#FFFFFF'}}>&nbsp;&nbsp;&nbsp;
       <img src={ToggleIcon} style={{cursor:'pointer'}} onClick={onTogMenu} alt=''/>&nbsp;&nbsp;&nbsp;
       <span style={{fontFamily:'Inter'}}>
         {subMenuItem}
       </span>
      </Col>
      </Row>:"" }
      <MobileMenu mobMenu={mobMenu} setMobMenu={setMobMenu}/>
    </div>:
    <div >
       <Row justify="space-around" style={{height:showSearch?'88px':'50px'}}>
        <Col {...responsiveMenuToggle} style={{paddingLeft:"1em",marginTop: "-0.6em"}}>
        <img src={ToggleIcon} style={{ cursor: "pointer", paddingBottom: "0.5px",width:"1.5em",height:"1.5em" }} onClick={onTogMenu} alt="" />
        </Col>
          <Col {...responsiveSearch1} style={{ marginTop: "-0.6em" }}>
          <img
          onClick={goToHome}
          style={{ cursor: "pointer",width:'60%',paddingLeft:'8%'}}
          src={NewLogo1}
          alt="Logo"
        />               
        </Col>
        <Col {...responsiveSearch} style={{marginTop:"-0.6em",float:"right"}}>
          <Row justify="space-between">
            <Col {...responsiveSpace}/>
            <Col {...responsiveUserIcon} style={{marginTop:"1.8em"}}>
              <div style={{display:"flex",justifyContent:"space-between"}}>
                <img onClick={()=>{setShowSearch((t)=>!t)}} style={{ cursor: "pointer",backgroundColor:'#FFFFFF' }} src={mobileGlobalSearch} preview={false} />
                <Popover content={content} trigger="click" placement="topRight">
                <img style={{ cursor: "pointer",backgroundColor:'#FFFFFF' }} src={UserIconMobile} preview={false} />
              </Popover>
              </div>
            </Col>
            <Col span={2}/>
            {/* <Col {...responsiveUserIcon} style={{marginRight:"0.3em"}}>
             
            </Col> */}
            
          </Row>
        </Col>
        <Col span={24} style={{marginTop:"-2em",padding:"0 2em",display:showSearch?"block":"none"}}> 
        <Select
            className="global-sel"
            showSearch
            style={{ width: "100%" ,padding:0,borderRadius:'5px',borderColor:'#D7DADE',border:'0.5px'}}
            value={null}
            onSelect={selectMenuToNavigate}
            suffixIcon={
              <CloseOutlined style={{color:"#3D4561",cursor:"pointer"}}  onClick={(ev)=>{ev.stopPropagation();  setShowSearch((t)=>!t)}}/>
            }
            placeholder={
              <Row>
                <Col style={{ textAlign: "right" }} span={1}>
                <img src={SearchIcon} alt='' style={{opacity:0.83,padding:"2px 2px 4px 2px",height:"19px"}}/>
                </Col>
                <Col span={22} style={{color:'#868B8F',width:'100%',fontFamily:'Inter',paddingLeft:7,textAlign:'left',fontWeight:400}}>Search</Col>
                {/* <Col style={{ textAlign: "right" }} onClick={(ev)=>{ev.stopPropagation()}} span={1} >
                <CloseOutlined style={{color:"#3D4561",cursor:"pointer"}}  onClick={(ev)=>{ev.stopPropagation();  setShowSearch((t)=>!t)}}/>
                </Col> */}
              </Row>
            }
            showArrow={false}
            // className="search-arrow placeHolder"
          >
            {menuData.map((menu) =>
                  menu.children ? (
                    <OptGroup label={<span style={{ fontSize: '13px', color: 'black' }}><strong>{menu.title}</strong></span>}>
                     {  menu.children.map((subMenuItem) =>
                      subMenuItem.children ? (
                        subMenuItem.children.map((lowerSubMenuItem) => (
                          <Option style={{fontSize:'12px',fontFamily:'Inter' }} key={lowerSubMenuItem.key} key1={lowerSubMenuItem.id} value={`${lowerSubMenuItem.title}@${lowerSubMenuItem.type}`}>
                            {lowerSubMenuItem.title}
                          </Option>
                        ))
                      ) : (
                        <Option style={{fontSize:'12px',fontFamily:'Inter'}} key={subMenuItem.key} key1={subMenuItem.id} value={`${subMenuItem.title}@${subMenuItem.type}`}>
                          {subMenuItem.title}
                        </Option>
                      )
                    )
                  }
                    </OptGroup>
                  ):(
                    <Option style={{fontSize:'12px',fontFamily:'Inter' }} key={menu.key} key1={menu.id} value={`${menu.title}@${menu.type}`}>
                      {menu.title}
                    </Option>
                  )
                )}
          </Select>
        </Col>
      </Row>
      <MobileMenu mobMenu={mobMenu} setSelectedMainMenuItem={setSelectedMainMenuItem} setMobMenu={setMobMenu} menuData={menuData}/>
    </div>
      }
    </>
  );
};

export default NavBar;
